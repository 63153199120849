import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  AclService,
  ContextService,
  CoreModule,
  ErrorModule,
  HttpAuthErrorsInterceptor,
  LayoutModule,
  ParamsInterceptor,
  UiDialogHttpErrorInterceptor,
  UiModule,
} from '@basic/libs';
import { SignalRConfiguration, SignalRModule } from 'ng2-signalr';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { BQAuthGuardService } from './auth/auth-guard.service';
import { QAIDBService } from './database/qa-idb.service';
import { PwaMessageModule } from './shared/components/pwa-message/pwa-message.module';
import { ConnectionService } from './shared/services/connection.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    UiModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRouting,
    ErrorModule,
    CoreModule.forRoot(),
    LayoutModule,
    OverlayModule,
    FormsModule,
    PwaMessageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SignalRModule.forRoot(createSignalRConfig)
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ParamsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UiDialogHttpErrorInterceptor, multi: true },
    BQAuthGuardService,
    ConnectionService,
    QAIDBService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private contextService: ContextService) {
    if (environment) {
      this.initContext(environment.protocol, environment.urlPart);
      this.initAcl();
    } else {
      console.error('Environment not loaded');
    }
  }

  private initContext(protocol: string, urlPart: string) {
    this.contextService.context.Settings.AppPaths.Core = {
      Base: protocol + '://accounts' + urlPart + '.basic.net/',
      Api: protocol + '://accounts' + urlPart + '.basic.net/'
    };
    this.contextService.context.Settings.AppPaths.Local = {
      Base: protocol + '://basicqa' + urlPart + '.basic.net',
      Api: protocol + '://basicqa-api' + urlPart + '.basic.net/'
    };
    this.contextService.context.Settings.AppPaths.Global = {
      Api: protocol + '://globalapi' + urlPart + '.basic.net/'
    };

    this.contextService.context.Settings.AppPaths.BasicNet = {
      Base: environment.protocol + '://my' + environment.urlPart + '.basic.net/'
    };

    this.contextService.context.Settings.AppPaths.BasicApp = {
      Base: environment.protocol + '://basicapp' + environment.urlPart + '.basic.net/'
    };

    this.contextService.context.Settings.AppPaths.BasicPlatformHub = {
      Base: environment.protocol + '://basicplatformhub' + environment.urlPart + '.basic.net/'
    };

    this.contextService.context.Settings.LoginUrl = this.contextService.context.Settings.AppPaths.Core.Base + 'account/login';
    this.contextService.context.Settings.LogoffUrl = this.contextService.context.Settings.AppPaths.Core.Base + 'account/clientlogoff';
  }

  private initAcl() {
    AclService.useAcl = environment.useAcl;
  }
}

export function createSignalRConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'BNNotificationsHub';
  c.withCredentials = true;
  c.logging = false;
  c.jsonp = true;
  return c;
}
