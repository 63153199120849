<ng-container *ngIf="deferredPrompt && showbar">
  <!-- Android -->
  <ng-container *ngIf="isMobile; else pwaMessageDesktop">
    <ng-container *ngIf="!(isIOS && isSafari); else pwaMessageIOs">
      <div class="pwa-message"
           (click)="addAppToDesktop()">
        <div class="pwa-message-text ">
          <img [src]="appIcon" />
          <div class="msg">
            Add
            <span style="color: rgb(0, 51, 102)">Basic</span>
            <span style="color: rgb(192, 0, 0)">QA</span>
            to Home screen
          </div>
          <button mat-icon-button
                  class="close-pwa-message"
                  (click)="closePwaMessageBar()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<!-- iOS -->
<ng-template #pwaMessageIOs>
  <div class="pwa-message-ios">
    <div class="full-width">
      <div class="content row">
        <button mat-icon-button
                class="col s12"
                (click)="closePwaMessageBar()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="col s12">
          Install this app on your device.
          <br />Tap the share icon and then <br /><strong>Add to homescreen</strong>.
        </div>
        <mat-icon class="col s12">arrow_downward</mat-icon>
      </div>
    </div>
  </div>
</ng-template>
<!-- Desktop -->
<ng-template #pwaMessageDesktop>
  <div class="pwa-message"
       (click)="addAppToDesktop()">
    <div class="pwa-message-text">
      <div class="image">
        <img class="col s1 margin-top-small"
             [src]="appIcon">
      </div>
      <div class="msg">
        Add BasicQA to Desktop
      </div>
      <button mat-icon-button
              class="close-pwa-message"
              (click)="closePwaMessageBar()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</ng-template>