import { version } from '../../package.json';
import { EnvironmentType } from './environment-type';

export const environment = {
  production: false,
  type: EnvironmentType.develop,
  urlPart: '.dev',
  protocol: 'http',
  version: parseInt(version.replace(/\./g, ''), 10),
  useAcl: true
};
