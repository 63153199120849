<ng-container *ngIf="online === true">
  <up-header></up-header>
  <main>
    <router-outlet></router-outlet>
    <bq-pwa-message [deferredPrompt]="deferredPrompt"></bq-pwa-message>
  </main>
  <up-footer></up-footer>
</ng-container>

<ng-container *ngIf="online === false">
  <main>
    <router-outlet></router-outlet>
    <bq-pwa-message [deferredPrompt]="deferredPrompt"></bq-pwa-message>
  </main>
</ng-container>

<ng-template #newVersionDialogTemplate>
  <h2 mat-dialog-title>Update available</h2>
  <div class="dialog-content">Update available for this page, please reload this page as soon as possible.</div>
  <div class="dialog-actions">
    <button mat-button
            (click)="closeDialog()">
      Close
    </button>
  </div>
</ng-template>