import { Injectable } from '@angular/core';
import { IndexedDBService, StoreIndex, StoreItem } from '@basic/libs';
import { environment } from 'src/environments/environment';

const QAIDBName: string = 'BasicQA';
const QAIDBVersion: number = environment.version;

const versionName = 'BasicQAIDBVersion';

export const QAIDBConfig = {
    inspection: {
        name: 'Inspection', index: {
            request: { name: 'Request', key: 'RequestId' }
        }
    }
};

function createQAIDBStore(): StoreItem[] {
    const result: StoreItem[] = [];

    for (const propItem in QAIDBConfig) {
        const item = QAIDBConfig[propItem];
        const storeItem = new StoreItem({ Name: item.name, Options: { autoIncrement: false } });
        storeItem.Index = [];

        for (const propIndex in item.index) {
            const index = item.index[propIndex];
            storeItem.Index.push(new StoreIndex({ Name: index.name, KeyPath: index.key, Unique: true }));
        }

        result.push(storeItem);
    }

    return result;
}

@Injectable()
export class QAIDBService extends IndexedDBService {

    constructor() {
        const QAIDBStore = createQAIDBStore();
        super(QAIDBName, QAIDBVersion, QAIDBStore);

        const localVersion = parseInt(localStorage.getItem(versionName), 10);
        if (localVersion && QAIDBVersion !== localVersion) {
            QAIDBStore.forEach((storeItem: StoreItem) => super.clear(storeItem.Name));
        }

        localStorage.setItem(versionName, QAIDBVersion.toString());
    }

}
