import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ContextService, loaderAnimation, UiDialogService } from '@basic/libs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.dev';

import { ConnectionService } from './shared/services/connection.service';

const versionName: string = 'BasicQAVersion';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [loaderAnimation]
})
export class AppComponent implements OnInit, AfterViewInit {

  // View references
  @ViewChild('newVersionDialogTemplate') public newVersionDialogTemplate;

  // Constructor
  constructor(private _dialogService: UiDialogService,
    private _connectionService: ConnectionService,
    public _context: ContextService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title) {
    this.subscribeNavigationEnd();
  }

  // Public variables
  public loading: boolean;
  public hideHeaderAndFooter: boolean;
  public style: any = { 'min-height': '0' };

  public online: boolean = null;
  public deferredPrompt;

  // Lifecycle hooks
  public ngOnInit() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });

    this._connectionService.online.subscribe((value: boolean) => {
      this.online = value;
    });

    this.checkAppVersion(environment.version);
  }

  public ngAfterViewInit() {
    this.onWindowResize();
  }

  // Events
  @HostListener('window:resize', []) public onWindowResize() {
    if (document.getElementsByTagName('router-outlet').length && document.getElementsByTagName('router-outlet')[0].nextSibling) {
      (<any>document.getElementsByTagName('router-outlet')[0].nextSibling).style.left =
        'calc(50% - ' + ((<any>document.getElementsByTagName('router-outlet')[0].nextSibling).clientWidth / 2) + 'px)';
    }
  }

  // Public functions
  public getRouterOutletState(outlet: RouterOutlet): any {
    this.style = {
      'min-height':
        (document.getElementsByTagName('router-outlet').length > 0 && document.getElementsByTagName('router-outlet')[0].nextSibling) ?
          ((<any>document.getElementsByTagName('router-outlet')[0].nextSibling).offsetHeight + 20) + 'px' :
          '0'
    };

    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public activateRoute() {
    (<any>document.getElementsByTagName('router-outlet')[0].nextSibling).style.position = 'initial';
  }

  public deactivateRoute() {
    (<any>document.getElementsByTagName('router-outlet')[0].nextSibling).style.position = 'absolute';
  }

  public addAppHomeScreen() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  public closeDialog() {
    this._dialogService.closeDialog();
  }

  // Private functions
  private subscribeNavigationEnd() {
    this._router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => {
          let route = this._activatedRoute.firstChild;
          let child = route;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }

          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        const title = data['title'];
        if (title) {
          this._titleService.setTitle(title);
        }
      });
  }

  private checkAppVersion(version: number) {
    const localVersion = parseInt(localStorage.getItem(versionName), 10);

    if (localVersion && version !== localVersion) {
      this._dialogService.openDialog(this.newVersionDialogTemplate);
    }

    localStorage.setItem(versionName, version.toString());
  }

}
