import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectionService {

    // Subject and observables
    private _online$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public get online(): Observable<boolean> { return this._online$.asObservable(); }

    // Public functions
    public setOnline(online: boolean) {
        this._online$.next(online);
    }

}
