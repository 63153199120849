import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwaMessageComponent } from './pwa-message.component';
import { CoreModule, UiModule } from '@basic/libs';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    UiModule
  ],
  declarations: [
    PwaMessageComponent
  ],
  exports: [
    PwaMessageComponent
  ]
})
export class PwaMessageModule { }
