import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QualityAssuranceUrl } from './routes/quality-assurance.url';

const routes: Routes = [
  { path: '', redirectTo: QualityAssuranceUrl.Root + '/' + QualityAssuranceUrl.Inspectors, pathMatch: 'full' },
  { path: QualityAssuranceUrl.Root, loadChildren: () => import('./routes/quality-assurance.routing').then(m => m.QualityAssuranceRouting) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouting { }
