import { Component, Input, OnInit } from '@angular/core';
import { NavigatorService, Utilities } from '@basic/libs';

@Component({
  selector: 'bq-pwa-message',
  templateUrl: './pwa-message.component.html',
  styleUrls: ['./pwa-message.component.scss'],
  providers: [Utilities.ProvideParent(PwaMessageComponent)]
})
export class PwaMessageComponent implements OnInit {

  @Input() public deferredPrompt;

  public eventWrapped: boolean;
  public showbar: boolean = true;
  public showButton = true;
  public isMobile: boolean;
  public isIOS: boolean;
  public isSafari: boolean;
  public appIcon: string = './assets/favicon/android-icon-48x48.png';

  constructor(private _navigator: NavigatorService) { }

  public ngOnInit() {
    this.detectMobile();
  }

  public addAppToDesktop() {
    this.deferredPrompt.prompt();

    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        console.log('addAppToDesktop choiceResult: ', choiceResult);
        if (choiceResult.outcome === 'accepted') {
          this.closePwaMessageBar();
        }
        this.deferredPrompt = null;
      });
  }

  public closePwaMessageBar() {
    this.showbar = false;
  }

  private detectMobile() {
    this.isMobile = this._navigator.isMobile();
    if (this.isMobile) {
      this.isIOS = this._navigator.isIOS();
      this.isSafari = this._navigator.isSafari();
    }
  }
}
