import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthGuardService, ContextService } from '@basic/libs';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentType } from 'src/environments/environment-type';

import { ConnectionService } from '../shared/services/connection.service';

@Injectable()
export class BQAuthGuardService extends AuthGuardService {
  constructor(private httpClient: HttpClient, private contextService: ContextService, private router: Router, private connectionService: ConnectionService) {
    super(httpClient, contextService, router);
  }

  public canActivate(currentRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const subject = new Subject<boolean>();

    const currentPage = state.url;
    const authPage = this.contextService.context.Settings.AppPaths.Core.Api + 'account/GetPageAuthContext';
    const pageUrl = this.contextService.context.Settings.AppPaths.Local.Base + currentPage;

    const params = new HttpParams({ fromObject: { url: pageUrl } });

    this.httpClient.get(authPage, { params: params, withCredentials: true })
      .pipe(
        map(res => {
          if (environment.type === EnvironmentType.local || environment.type === EnvironmentType.develop) {
            console.log(res);
          }
          if (currentRoute.routeConfig) {
            this.mapContext(res);
          }
          return true;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return throwError(err);
        })
      ).subscribe({
        next: (res) => {
          subject.next(true);
          this.connectionService.setOnline(true);
        },
        error: (err: HttpErrorResponse) => {
          subject.next(err.status === 504);
          this.connectionService.setOnline(err.status !== 504);

          if (err.status === 401 || err.status === 404) {
            super.handleHttpError(err.status);
          }
        }
      });

    return subject.asObservable();
  }

  private mapContext(pageInfo: any) {
    this.contextService.context.Current = pageInfo;
    this.contextService.setObservableContext(this.contextService.context);
  }
}
